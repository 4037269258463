import React, { useState, useEffect } from "react";

import styles from "./styles.module.css";

import BG from "../../assets/DesktopWallpaper.png";
import MobieleBG from "../../assets/MobileWallpaper.png";
import LOGO from "../../assets/TransparentBG.png";

const ComingSoon = () => {
  const [imgSrc, setImgSrc] = useState(BG);
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    // Initial update on page load
    updateImageSource();
  }, []);

  const updateImageSource = () => {
    const windowWidth = window.innerWidth;

    // Change the image source based on the screen width
    if (windowWidth < 769) {
      // For small screens (< 576px)
      setImgSrc(MobieleBG);
    } else {
      setImgSrc(BG);
    }
  };

  // Add event listener to detect window width changes
  window.addEventListener("resize", updateImageSource);

  const submitFunc = (e) => {
    e.preventDefault();

    setUserInput("");
  };

  return (
    <div className={styles.soon_container}>
      <img src={imgSrc} alt="Background Wallpaper" className={styles.soon_background} />
      <section className={styles.soon_content_wrapper}>
        <div className={styles.soon_content}>
          <div className={styles.soon_corner} />
          <div className={styles.soon_main_wrapper}>
            <img src={LOGO} alt="Waifu Academy Logo" className={styles.logo} />
            <h1>Coming Soon</h1>
            <form className={styles.soon_form} onSubmit={(e) => submitFunc(e)}>
              <input
                type="email"
                placeholder="Email Address"
                className={styles.soon_input}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
              <input type="submit" value="Get Notified" className={styles.soon_btn} />
            </form>
            <h4>We Are Excited To Meet Everyone Of You</h4>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComingSoon;
